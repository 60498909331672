import { useMemo, useState } from "react";

import { Zoom } from "../../Zoom";

import ZoomInIcon from "../../../images/zoom-in.png";

export function Option({
  optionId,
  value,
  image,
  isPraticeSession,
  isCorrectOption,
  isSelected,
  selectOption,
}) {
  const [zoom, setZoom] = useState(false);

  const showSelected = useMemo(() => {
    return isPraticeSession ? isSelected : isCorrectOption;
  }, [isPraticeSession, isCorrectOption, isSelected]);

  const checkStyle = useMemo(() => {
    if (!showSelected) return null;

    if (isPraticeSession) {
      return {
        backgroundColor: "#4bdf3f",
      };
    } else {
      return {
        backgroundColor: "#ff8cb0",
      };
    }
  }, [isPraticeSession, showSelected]);

  return (
    <div
      className={
        isPraticeSession ? "opt1-container ps-op1" : "opt1-container ot-op1"
      }
      style={{
        backgroundColor: isSelected ? "#CDFFE1" : "",
      }}
    >
      <div
        onClick={selectOption}
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <span
          style={{ fontSize: "1.1rem" }}
          dangerouslySetInnerHTML={{
            __html:
              value ||
              "<span style='visibility:hidden !important'>Empty Option</span>",
          }}
        ></span>
        <input type="radio" name="radio" id={optionId} />
        <div
          className={
            isPraticeSession ? "checkmark ps_check" : "checkmark ot_check"
          }
        >
          <span
            style={{
              display: "block",
              width: "12px",
              height: "12px",
              margin: "auto",
              borderRadius: "100%",
              ...checkStyle,
            }}
          ></span>
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            cursor: "pointer",
            zIndex: 10,
          }}
        ></div>
      </div>

      <div
        className="imgBlock"
        onClick={() => setZoom(true)}
        style={
          image
            ? {
                position: "relative",
                display: "flex",
                width: "50%",
                maxWidth: "300px",
                height: "fit-content",
                margin: "25px",
                cursor: "zoom-in",
                borderRadius: "0px",
              }
            : { display: "none" }
        }
      >
        <img
          src={image}
          style={{
            width: "100%",
            maxWidth: "fit-content",
            maxHeight: "fit-content",
          }}
        />
        <img
          src={ZoomInIcon}
          alt="zoom"
          style={{
            width: "20px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 50,
          }}
        />
      </div>
      {image && (
        <Zoom
          image={image}
          zoom={zoom}
          close={() => setZoom(false)}
          hideIcon={true}
        />
      )}
    </div>
  );
}

export function QuestionImg(props) {
  const [zoom, setZoom] = useState(false);
  return (
    <div className="image-wrapper" style={{ position: "relative" }}>
      <img onClick={() => setZoom(true)} src={props.image} />
      <Zoom image={props.image} zoom={zoom} close={() => setZoom(false)} />
    </div>
  );
}
