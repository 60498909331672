import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "./dropdownComponent.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: "80%",
    border: "1px solid #F1F1FA",
    borderRadius: "10px",
    color: "gray",
    margin: "10px auto",
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#4bdf3f",
      borderRadius: "15px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#2e682a",
    },
  }),
  control: () => ({
    display: "flex",
    padding: "4px",
    borderRadius: "15px",
    border: "1px solid #F1F1FA",
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default function DropdownComponent({
  type,
  placeholder,
  practiceAssessment,
  setPracticeAssessment,
  onlinetest,
  content,
  setSectionId,
  setSubjectId,
  setChapterId,
  topicId,
  setTopicId,
  disable,
  setModuleStatus,
  checkFilter,
  detailspan,
}) {
  const [selectedValue, setSelectedValue] = useState([]);
  const animatedComponents = makeAnimated();

  const options = useMemo(() => {
    if (!content) return [];
    if (["chapter", "topic"].includes(type))
      content = content.filter((item) => item.completed !== false);

    return content.map((x) => {
      return {
        ["value"]: x?.name || x?.section || x?.subject,
        ["label"]: x?.name || x?.section || x?.subject,
        ["id"]: x?._id || x?.section,
      };
    });
  }, [content]);

  return ["topic", "chapter", "sections"].includes(type) ? (
    <Select
      styles={customStyles}
      options={options}
      closeMenuOnSelect={false}
      components={animatedComponents}
      placeholder={placeholder || (type === "topic" ? "Topic" : "Chapter")}
      className={`${
        practiceAssessment.chapter && practiceAssessment.chapter != "Chapter"
          ? ""
          : "disableColor"
      } ${
        checkFilter ? `dropdown mb-3 menulistFilter` : `dropdown mb-3 w-100`
      }`}
      isDisabled={
        type === "topic"
          ? practiceAssessment.chapter &&
            practiceAssessment.chapter != "Chapter" &&
            practiceAssessment?.chapter?.length <= 1
            ? false
            : true
          : disable
      }
      value={selectedValue}
      onChange={(selectedOption) => {
        let arrValue = [];
        let arrValue2 = [];
        selectedOption.forEach((element) => {
          arrValue2.push(element.id);
          arrValue.push(element.value);
        });
        setSelectedValue(selectedOption);
        setPracticeAssessment({
          ...practiceAssessment,
          [type === "sections" ? "section" : type]: arrValue,
        });
        switch (type) {
          case "chapter":
            setChapterId(arrValue2);
            break;
          case "topic":
            setTopicId(arrValue2);
            break;
        }
      }}
      isMulti
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#CDFFE1",
        },
      })}
    />
  ) : (
    <div>
      <Dropdown className="dropdown mb-3 detailed_dash">
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className="dropdown_button truncate"
          disabled={disable}
        >
          {type === "section"
            ? practiceAssessment?.section
            : practiceAssessment[type].substring(0, 20) +
              `${practiceAssessment[type].length > 20 ? "..." : ""}`}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={
            onlinetest
              ? `onl-dropdown ${
                  type === "class" ||
                  type === "section" ||
                  type === "subject" ||
                  type === "tyt" ||
                  type === "chapter" ||
                  type === "topic" ||
                  type === "subtopic"
                    ? "menu_option_extend scrollmenu"
                    : ""
                }`
              : `pra-dropdown ${
                  type === "class" ||
                  type === "section" ||
                  type === "subject" ||
                  type === "tyt" ||
                  type === "chapter" ||
                  type === "topic" ||
                  type === "subtopic"
                    ? "menu_option_extend"
                    : ""
                }`
          }
        >
          {content.length != 0 ? (
            (type === "section" ? content.sort() : content)?.map(
              (list, index) => (
                <>
                  {type === "gradesection" ? (
                    <Dropdown.Item
                      onSelect={() => {
                        setPracticeAssessment({
                          ...practiceAssessment,
                          [type]: list.section || list,
                        });
                      }}
                      key={index}
                      eventKey={list.section || list}
                      className={
                        onlinetest
                          ? "menu_option onl-drop-list"
                          : "menu_option pra-drop-list"
                      }
                    >
                      {list.value || list}
                      <div>
                        <input type="radio" name="radio" />
                        <span
                          style={
                            detailspan
                              ? { border: "3px solid #10A0B6" }
                              : onlinetest
                              ? { border: "3px solid #FF8CB0" }
                              : { border: "3px solid #4bdf3f" }
                          }
                          className={onlinetest ? "onlinecheck" : ""}
                        ></span>
                      </div>
                    </Dropdown.Item>
                  ) : type === "tyt" ? (
                    <Dropdown.Item
                      onSelect={() => {
                        setPracticeAssessment({
                          ...practiceAssessment,
                          [type]: list.value || list,
                        });
                      }}
                      key={index}
                      eventKey={list.value}
                      className={
                        onlinetest
                          ? "menu_option onl-drop-list"
                          : "menu_option pra-drop-list"
                      }
                    >
                      {list.value || list}
                      <div>
                        <input type="radio" name="radio" />
                        <span
                          style={
                            onlinetest
                              ? { border: "3px solid #FF8CB0" }
                              : { border: "3px solid #4bdf3f" }
                          }
                          className={onlinetest ? "onlinecheck" : ""}
                        ></span>
                      </div>
                    </Dropdown.Item>
                  ) : type === "subject" ? (
                    <>
                      <Dropdown.Item
                        onSelect={() => {
                          setPracticeAssessment({
                            ...practiceAssessment,
                            [type]: list.subject,
                          });
                          setSubjectId(list.subjectId);
                          setSectionId(list.sectionId);
                        }}
                        key={index}
                        eventKey={list.subject}
                        className={
                          onlinetest
                            ? "menu_option onl-drop-list"
                            : "menu_option pra-drop-list"
                        }
                      >
                        {list.subject}
                        <div>
                          <input type="radio" name="radio" />
                          <span
                            style={
                              detailspan
                                ? { border: "3px solid #10A0B6" }
                                : onlinetest
                                ? { border: "3px solid #FF8CB0" }
                                : { border: "3px solid #4bdf3f" }
                            }
                            className={onlinetest ? "onlinecheck" : ""}
                          ></span>
                        </div>
                      </Dropdown.Item>
                    </>
                  ) : type === "section" ? (
                    <>
                      <Dropdown.Item
                        onSelect={() => {
                          setPracticeAssessment({
                            ...practiceAssessment,
                            [type]: [list.section],
                          });
                        }}
                        key={index}
                        eventKey={list.section}
                        className={
                          onlinetest
                            ? "menu_option onl-drop-list"
                            : "menu_option pra-drop-list"
                        }
                      >
                        {list.section}
                        <div>
                          <input type="radio" name="radio" />
                          <span
                            style={
                              detailspan
                                ? { border: "3px solid #10A0B6" }
                                : onlinetest
                                ? { border: "3px solid #FF8CB0" }
                                : { border: "3px solid #4bdf3f" }
                            }
                            className={onlinetest ? "onlinecheck" : ""}
                          ></span>
                        </div>
                      </Dropdown.Item>
                    </>
                  ) : type === "subtopic" ? (
                    <>
                      <Dropdown.Item
                        onSelect={() => {
                          setPracticeAssessment({
                            ...practiceAssessment,
                            [type]: list.name,
                          });
                        }}
                        key={index}
                        eventKey={list.name}
                        className={
                          onlinetest
                            ? "menu_option onl-drop-list"
                            : "menu_option pra-drop-list"
                        }
                      >
                        {list.name}
                        <div>
                          <input type="radio" name="radio" />
                          <span
                            style={
                              onlinetest
                                ? { border: "3px solid #FF8CB0" }
                                : { border: "3px solid #4bdf3f" }
                            }
                            className={onlinetest ? "onlinecheck" : ""}
                          ></span>
                        </div>
                      </Dropdown.Item>
                    </>
                  ) : (
                    <Dropdown.Item
                      onSelect={() => {
                        setPracticeAssessment({
                          ...practiceAssessment,
                          [type]: list,
                        });
                      }}
                      key={index}
                      eventKey={list}
                      className={
                        onlinetest
                          ? "menu_option onl-drop-list"
                          : "menu_option pra-drop-list"
                      }
                    >
                      {list}
                      <div>
                        <input type="radio" name="radio" />
                        <span
                          style={
                            detailspan
                              ? { border: "3px solid #10A0B6" }
                              : onlinetest
                              ? { border: "3px solid #FF8CB0" }
                              : { border: "3px solid #4bdf3f" }
                          }
                          className={onlinetest ? "onlinecheck" : ""}
                        ></span>
                      </div>
                    </Dropdown.Item>
                  )}
                </>
              )
            )
          ) : (
            <Dropdown.Item
              className={
                onlinetest
                  ? "menu_option onl-drop-list"
                  : "menu_option pra-drop-list"
              }
            >
              <div>{`No ${type} is Completed`}</div>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
