import React, { useState, useEffect, useRef, useContext } from "react";
import "./styles/Updates.css";
import TextField from "@material-ui/core/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as AccountIcon } from "../../images/AnnouncementUserLogo.svg";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CardHeader, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import Attach_ana from "../../images/Attach_ana.svg";
import { useAlert } from "react-alert";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReactComponent as CalendarNext } from "../../images/Next.svg";
import { ReactComponent as CalendarPrev } from "../../images/Prev.svg";
import { ReactComponent as AnnouncementIcon } from "../../images/Announcement.svg";
import { Modal } from "react-bootstrap";
import DropdownComponent from "../DropdownComponent/DropdownComponent";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../../context/GlobalState";
import LessonPlans from "../LessonPlan/LessonPlans/LessonPlans";
import { startOfDay, isSameDay } from "date-fns";
import axios from "axios";

import { LessonPlanAPIs } from "../LessonPlan/api";
import { locale } from "moment";
import LessonPlan from "../LessonPlan/LessonPlans/LessonPlan";

const useStyles = makeStyles({
  root: {
    minWidth: "auto",
    borderRadius: "15px !important",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  calendar: {
    marginTop: "1rem",
    margin: "auto",
    borderRadius: "15px",
    padding: "10px",
    border: "1px solid #b5b2b2 !important",
  },
  selectedDate: {
    borderRadius: "50%",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#ffffff !important",
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
});

const Updates = ({
  grader,
  sectionsel,
  subject,
  selectedDetails,
  setSelectedDetails,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const selectedClass = localStorage.getItem("selectedClass");
  const showMessage = (error, success = false) =>
    alert.show(error, { type: success ? "success" : "error" });
  const profileImageUrl = localStorage.getItem("profileImageUrl");
  const grade = selectedClass?.split("-")[0];
  const section = selectedClass?.split("-")[1];
  const { userDetails } = useContext(GlobalContext);
  //State for calendar
  const [value, onChange] = useState(new Date());
  const [plans, setPlans] = useState([]);
  const [activities, setActivities] = useState([]);
  //State and function to create an announcement
  const announcementRef = useRef("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //State and function to get the announcements
  const [announcements, setAnnouncements] = useState([]);
  const [fetched, setFetched] = useState(false);
  const getAnnouncements = async () => {
    setFetched(true);
    try {
      await fetch(
        // `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${selectedDetails.class}/sections/${selectedDetails.section}/announcements?sort=-createdAt`,
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/annoucements?sort=-createdAt`,
        {
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setAnnouncements(response.data.announcements);
          } else {
            alert.show(response.message);
          }
        });
    } catch (err) {
      alert.show(err.message);
    }
    setFetched(false);
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  //Function to toggle the menu on top right of eah announcement card
  const toggleMenu = (id) => {
    if (document.getElementById(id).style.display === "none") {
      document.getElementById(id).style.display = "block";
    } else {
      document.getElementById(id).style.display = "none";
    }
  };

  //Function to delete announcement card
  const deleteAnnouncement = async (id) => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/announcements/${id}`, {
      method: "DELETE",
      headers: {
        token: `${window.localStorage.getItem("authToken")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert.show("Deleted announcement successfully", { type: "success" });
          getAnnouncements();
        } else {
          alert.show("Failed to delete announcement", { type: "error" });
        }
      });
  };

  const [addTo, setAddTo] = useState("all");

  const handleAddTo = (e) => {
    setAddTo(e.target.value);
  };

  const makeNewAnnouncement = () => {
    const announcement = announcementRef?.current?.value;

    if (!announcement || !announcement.length)
      return showMessage("Announcement should not be empty");

    // const announcement = newAnnouncement;
    if (userDetails.role === "principal") {
      fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/grades/none/sections/none/announcements`,
        {
          method: "POST",
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ announcement, receiver: `${addTo}` }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.status === "success") {
            showMessage("New announcement added successfully", true);
            announcementRef.current.value = "";
            getAnnouncements();
          } else {
            showMessage("Failed to make new announcement");
            announcementRef.current.value = "";
          }
        });
    } else {
      // validation to check empty  grade , announcement
      if (selectedDetails.class === "Class")
        return showMessage("Please select a class");
      fetch(
        `${process.env.REACT_APP_ORIGIN2}/api/v1/staffs/annoucements?grade=${
          selectedDetails.class === "Class" ? grade : selectedDetails.class
        }&section=${
          selectedDetails.gradesection === "Section"
            ? section
            : selectedDetails.gradesection
        }&subjectId=${sectionId}`,
        {
          method: "POST",
          headers: {
            token: `${window.localStorage.getItem("authToken")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ announcement }),
        }
      )
        .then((response) => {
          console.log("Server response: ", response);
          return response.json();
        })
        .then((response) => {
          if (response.status === "success") {
            showMessage("New announcement added successfully", true);
            announcementRef.current.value = "";
            getAnnouncements();
          } else {
            showMessage("Failed to make new announcement");
            announcementRef.current.value = "";
          }
        });
    }
  };

  const [editModal, setEditModal] = useState(false);
  const [eAnnouncement, setEAnnouncement] = useState(null);
  const handleEditClose = () => {
    setEditModal(false);
    setEAnnouncement(null);
  };
  //Function to edit annoucement
  const handleEditAnnouncement = async (e) => {
    setEAnnouncement(e);
    setEditModal(true);
  };

  const editAnnouncement = async () => {
    console.log(eAnnouncement);
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/announcements/${eAnnouncement._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token: `${window.localStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ announcement: eAnnouncement?.announcement }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === "success") {
          showMessage("Announcement Updated successfully", true);
          getAnnouncements();
        } else {
          showMessage("Failed to update announcement");
        }
      })
      .catch((err) => showMessage("Failed to Update Announcement"));
  };

  //Function to convert strings into possible links
  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="blank" href="  ' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }
  const [details, setDetails] = useState(false);
  const handleClose = () => setDetails(false);
  const handleShow = () => setDetails(true);
  const [sectionId, setSectionId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });

  function fetchLessonPlans(date) {
    const query = `startDate[gte]=${date.toISOString()}&startDate[lte]=${new Date(date.getTime() + (28 * 24 * 60 * 60 * 1000)).toISOString()}`;
    axios
      .get(`${LessonPlanAPIs.fetchLessonPlans(userDetails.userId)}?${query}`, {
        headers: {
          token: window.localStorage.getItem("authToken"),
        },
        validateStatus: () => true,
      })
      .then((res) => {
        if (res.data.status !== "success") throw res.data;
        setPlans(res.data.data.lessonPlans);
      })
      .catch((err) => console.log(err.message));
  }

  //fetch lesson plans
  useEffect(() => {
    const date = new Date();
    fetchLessonPlans(date);
  }, []);

  useEffect(() => {
    const activeDates = [];
    plans.forEach((plan) => {
      const day = startOfDay(new Date(plan.startDate));
      if (!activeDates.includes(day)) activeDates.push(day);
    });
    setActivities(activeDates);
  }, [plans]);

  return (
    <>
      {/* edit announcement modal */}
      <Modal
        show={editModal}
        animation={false}
        centered
        onHide={handleEditClose}
        className="Detail_modal"
      >
        <Modal.Body>
          <div className="upload_done_modal">
            <h4>Edit Announcement</h4>
            <TextField
              id="standard-basic"
              placeholder="Make an announcement"
              className={"inputField"}
              multiline
              maxRows={4}
              value={eAnnouncement?.announcement}
              onChange={(e) =>
                setEAnnouncement((old) => {
                  return { ...old, announcement: e.target.value };
                })
              }
              // inputRef={eAnnouncement}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              // variant="outlined"
              style={{
                fontFamily: "Poppins !important",
              }}
            />
            <div
              className="detailed_modal__bottom"
              style={{ marginTop: "20px" }}
            >
              <button className="btn detail_button" onClick={handleEditClose}>
                Cancel
              </button>
              <button
                className="btn detail_button1"
                onClick={() => {
                  editAnnouncement();
                  handleEditClose();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* class section subject modal */}
      <Modal
        show={details}
        onHide={handleClose}
        animation={false}
        centered
        className="Detail_modal"
      >
        <Modal.Body>
          {userDetails.role !== "principal" ? (
            <div className="upload_done_modal">
              <div className="upload_done_modal__top">
                <AnnouncementIcon />
                <span style={{ fontWeight: "600" }}>Announcement</span>
              </div>
              <div className="detailed_modal__mid">
                <DropdownComponent
                  type="class"
                  practiceAssessment={selectedDetails}
                  setPracticeAssessment={setSelectedDetails}
                  content={grader}
                  style={{ marginLeft: "20px !important" }}
                  detailspan
                />
                <DropdownComponent
                  type="gradesection"
                  practiceAssessment={selectedDetails}
                  setPracticeAssessment={setSelectedDetails}
                  content={sectionsel}
                  disable={
                    selectedDetails.class && selectedDetails.class != "Class"
                      ? false
                      : true
                  }
                  style={{ marginLeft: "20px !important" }}
                  detailspan
                />
                <DropdownComponent
                  type="subject"
                  practiceAssessment={selectedDetails}
                  setPracticeAssessment={setSelectedDetails}
                  content={subject}
                  setSubjectId={setSubjectId}
                  setSectionId={setSectionId}
                  disable={
                    selectedDetails.gradesection &&
                    selectedDetails.gradesection != "Section"
                      ? false
                      : true
                  }
                  style={{ marginLeft: "20px !important" }}
                  detailspan
                />
              </div>
              <div className="detailed_modal__bottom">
                <button className="btn detail_button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn detail_button1"
                  onClick={() => {
                    makeNewAnnouncement();
                    handleClose();
                    setSelectedDetails({
                      class: "Class",
                      gradesection: "Section",
                      subject: "Subject",
                    });
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          ) : (
            <div className="upload_done_modal">
              <div className="upload_done_modal__top">
                <AnnouncementIcon />
                <span style={{ fontWeight: "600" }}>Announcement</span>
              </div>
              <div
                className="detailed_modal__mid"
                style={{ marginLeft: "20px", marginBottom: "20px" }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={addTo}
                    onChange={handleAddTo}
                  >
                    <FormControlLabel
                      value="students"
                      control={<Radio />}
                      label="Students"
                    />
                    <FormControlLabel
                      value="teachers"
                      control={<Radio />}
                      label="Teachers"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="ALL"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="detailed_modal__bottom">
                <button className="btn detail_button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn detail_button1"
                  onClick={() => {
                    makeNewAnnouncement();
                    handleClose();
                    setSelectedDetails({
                      class: "Class",
                      section: "Section",
                      subject: "Subject",
                    });
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* Section to create an announcement */}
      <section className="updates my-4 col-sm-11 mx-auto">
        <div
          className="inputBox "
          // style={{ marginTop: isSmallScreen ? "100px" : "" }}
        >
          <div item className="inputAnnounce">
            {!["undefined", undefined, null, "null"].includes(
              profileImageUrl
            ) ? (
              <img
                src={profileImageUrl}
                width="40px"
                height="40px"
                style={{ borderRadius: "50px" }}
                className="userImage"
              />
            ) : (
              <AccountIcon className="userImage" />
            )}
            <TextField
              id="input-with-icon-grid"
              // label='Make an announcement'
              placeholder="Make an announcement"
              className={"inputField"}
              inputRef={announcementRef}
              multiline
              maxRows={4}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              // variant="outlined"
              style={{
                fontFamily: "Poppins !important",
              }}
            />
            <div>
              <img src={Attach_ana} className="attachIcon_ann" />
            </div>
            <div className="arrowAt">
              {/* <img src={announceArrow}
                  type='button'
                  onClick={handleShow}
                /> */}
              <IconButton onClick={handleShow}>
                <SendOutlinedIcon style={{ color: "white !important" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </section>

      {/* Section to display the announcements  */}

      <section className="postCard">
        <div className="row adjustWidth">
          {selectedDetails !== "Class" ? (
            <div
              className="col-sm-8 announcement_list"
              style={{ height: "700px", overflowY: "scroll" }}
            >
              {announcements.length !== 0 ? (
                announcements.map((e, idx) => {
                  // console.log(e.userId._id, userDetails.userId, e, userDetails)
                  const currentDate = new Date(e.createdAt);
                  return (
                    <>
                      <Card
                        className={`${classes.root} mb-5`}
                        key={`${e.userId._id}${idx}`}
                        style={{ background: "rgba(241, 241, 250, 0.3)" }}
                      >
                        <CardHeader
                          action={
                            <>
                              {e.userId._id === userDetails.userId ? (
                                <>
                                  <IconButton
                                    aria-label="settings"
                                    onClick={() => {
                                      toggleMenu(e._id);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <ul
                                    className="dropdown-menu dropdown-width"
                                    style={{
                                      display: "none",
                                      position: "absolute",
                                      right: isSmallScreen ? "6px" : null,
                                    }}
                                    id={e._id}
                                  >
                                    <li>
                                      {e.userId._id === userDetails.userId ? (
                                        <Link
                                          className="dropdown-item"
                                          to="#"
                                          onClick={() => {
                                            handleEditAnnouncement(e);
                                          }}
                                        >
                                          Edit
                                        </Link>
                                      ) : null}
                                      {e.userId._id === userDetails.userId ? (
                                        <Link
                                          className="dropdown-item"
                                          to="#"
                                          onClick={() => {
                                            deleteAnnouncement(e._id);
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      ) : null}
                                    </li>
                                    {/* <li>
                            <Link
                              className='dropdown-item'
                              to='#'
                              // onClick={() => {
                              //   deleteAnnouncement(e._id);
                              // }}
                            >
                              Archive
                            </Link>
                          </li> */}
                                  </ul>{" "}
                                </>
                              ) : null}
                            </>
                          }
                          className="px-4"
                          avatar={
                            !["undefined", undefined, null, "null"].includes(
                              profileImageUrl
                            ) ? (
                              <img
                                src={profileImageUrl}
                                width="40px"
                                height="40px"
                                style={{ borderRadius: "50px" }}
                                className="userImage"
                              />
                            ) : (
                              <AccountIcon
                                className="userImage"
                                style={{ fontSize: "60px" }}
                              />
                            )
                          }
                          title={e.userId.name}
                          subheader={`${
                            months[currentDate.getMonth()]
                          } ${currentDate.getDate()}, ${currentDate.getFullYear()}`}
                        />
                        <CardContent>
                          <div
                            className="message px-2"
                            dangerouslySetInnerHTML={{
                              __html: urlify(e.announcement),
                            }}
                          ></div>
                        </CardContent>
                        {/* <Divider style={{ backgroundColor: "black" }}/>
                        <CardActions>
                          <div className="input-group mb-3 px-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Comment"
                            />
                            <button className="btn" type="button">
                              <ArrowRightOutlinedIcon />
                            </button>
                          </div>
                        </CardActions> */}
                      </Card>
                      {fetched && "Loading...."}
                    </>
                  );
                })
              ) : (
                <h5 className="text-center">{"No announcements here"}</h5>
              )}
            </div>
          ) : (
            <div className="col-sm-8" style={{ textAlign: "center" }}>
              <h3>Make announcements</h3>
            </div>
          )}
          <div className="col-sm-4 d-sm-block calendar-width">
            <h4 style={{ textAlign: "center", margin: "20px auto" }}>
              Calendar
            </h4>
            <Calendar
              className={classes.calendar}
              onChange={onChange}
              value={value}
              prevLabel={<CalendarPrev />}
              nextLabel={<CalendarNext />}
              prev2Label={null}
              next2Label={null}
              tileContent={({ date }) => {
                const localDate = startOfDay(date);
                if (activities.some((d) => isSameDay(localDate, new Date(d)))) {
                  return (
                    <div
                      style={{
                        width: "5px",
                        height: "5px",
                        background: "#FB2828",
                        borderRadius: "100%",
                        margin: "auto",
                      }}
                    ></div>
                  );
                }
              }}
              onActiveStartDateChange={function handleMonthChange({
                activeStartDate,
              }) {
                fetchLessonPlans(activeStartDate);
              }}
            />

            <div>
              <p style={{ margin: "10px 0",color:"#9D9D9D",fontSize:"14px" }}>{`${
                plans.filter((plan) => {
                  return isSameDay(startOfDay(value), new Date(plan.startDate));
                }).length
              } Activities on ${new Date(value).toDateString()}`}</p>

              {plans.map((plan) => {
                if (isSameDay(startOfDay(value), new Date(plan.startDate))) {
                  return <LessonPlan plan={plan} />;
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Updates;
