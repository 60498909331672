import "./onlineTest.css";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import React, { useState, useContext, useMemo } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Moment from "react-moment";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { GlobalContext } from "../../context/GlobalState";
import moduleImg from "../../images/module.svg";
import assessmentImg from "../../images/assessment.svg";
import randomQuesImg from "../../images/randomQues.svg";
import librarySelImg from "../../images/librarySel.svg";
import libautImg from "../../images/lib+aut.svg";
import analyticGraphImg from "../../images/analyticGraph.svg";
import assignImg from "../../images/assign.svg";
import McqCard from "../Questions/McqCard/McqCard";
import TFCard from "../Questions/TFCard/TFCard";
import OneWord from "../Questions/OneWord/OneWord";
import AuthorQues from "../AuthorQues/AuthorQues";
import { Modal } from "react-bootstrap";
import FillBlankCard from "../Questions/FillBlankCard/FillBlankCard";
import MmcqCard from "../Questions/MmcqCard/MmcqCard";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "../../images/HomeIcon.svg";
import QuestionMarks from "../QuestionMarks/QuestionMarks";
import axios from "axios";
import { useEffect } from "react";
import LeftArrow from "../../images/LeftArrow";
import RightArrow from "../../images/RightArrow";
import AssignedTestDetails from "./AssignedTestDetails";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalenderImg from "../../../src/images/Calender.svg";
import TextField from "@material-ui/core/TextField";
import DropdownComponent from "../DropdownComponent/DropdownComponent";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DropDownShutter from "../DropDown/DropDown";
import { TimeDurationInput } from "react-time-duration-input";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";
import { difference } from "lodash";
import moment from "moment";
import { groupGradeSections } from "../../utils/groupGrades";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#edfcfa",
    boxShadow: "none",
  },
  datePickerStyle: {
    backgroundColor: "transparent",
  },
}));
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    width: "80%",
    border: "1px solid #F1F1FA",
    borderRadius: "10px",
    color: "gray",
    margin: "10px auto",
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",

    "::-webkit-scrollbar": {
      width: "9px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#4bdf3f",
      borderRadius: "15px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#2e682a",
    },
  }),
  control: () => ({
    display: "flex",
    padding: "4px",
    borderRadius: "15px",
    border: "1px solid #F1F1FA",
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default function OnlineTest() {
  const animatedComponents = makeAnimated();
  let upindex = "";
  const [topicsSel, setTopicsSel] = useState([]);
  let [createTestData, setcreateData] = useState({});
  let [page, setPage] = useState(1);
  let [arrQues, setArrQues] = useState(null);
  const [addAuthorQues, setAddAuthorQues] = useState([1]);
  const userId = localStorage.getItem("userId");
  const moduleId = localStorage.getItem("moduleId");
  const authToken = localStorage.getItem("authToken");
  const [questions, setQuestions] = useState([]);
  let [questId, setQuestId] = useState("");
  let [selQuest, setSelQuest] = useState(null);
  const [fetchQuestionLoader, setFetchQuestionLoader] = useState(true);
  const [reviewQuestion, setReviewQuestion] = useState([]);
  const [testId, setTestId] = useState("");
  const [checked, setChecked] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [newQuestions, setNewQuestions] = useState([]);
  const [createTestMessage, setCreateTestMessage] = useState("");
  const [createTestLoader, setCreateTestLoader] = useState(false);
  const [assisgnTestLoader, setAssisgnTestLoader] = useState(false);
  const [difficulty, setDifficulty] = useState("Difficulty");
  const [cognitive, setCognitive] = useState("Cognitive");
  const [assignClass, setAssignClass] = useState("Class");
  const [assignSection, setAssignSection] = useState("Section");
  const [assignVisible, setAssignVisible] = useState("Visibility");
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [assignTime, setAssignTime] = useState({
    day: "",
    hours: "",
    minutes: "",
  });
  const [fileName, setFileName] = useState("");
  const [randomQuestionModal, setRandomQuestionModal] = useState({
    btn: false,
    validity: false,
  });
  const [librarySelection, setLibrarySelection] = useState({
    DLQ_Validity: true,
    Cog_Validity: true,
    validity: false,
    btn: false,
  });
  let location = useLocation();
  const param = useParams();
  const alert = useAlert();
  const filterDifficulty = {
    easy: [],
    intermediate: [],
    hard: [],
  };
  const filterCognitive = {
    knowledge: [],
    understanding: [],
    application: [],
    analysis: [],
  };
  const myassessment = location.pathname.split("/");

  const handleVisible = (e) => {
    setAssignVisible(e);
  };

  const handleClass = (e) => {
    setAssignClass(e);
  };
  const handleSelect1 = (e) => {
    setDifficulty(e);
  };
  const handleSelect2 = (e) => {
    // console.log(e);
    setCognitive(e);
  };
  const subjectId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("subjectId")
      : location.state.subjectId;
  const chapterId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("chapterId")
      : location.state.chapterId;
  const topicId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("topicId")
      : location.state.topicId;
  const sectionId =
    myassessment[1] != "myassessment"
      ? localStorage.getItem("sectionIdTask")
      : location.state.sectionId;
  const grade = location.state.grade;
  const subject = location.state.subject;
  let sections = [];
  if (myassessment[1] === "myassessment") {
    sections = [
      ...new Map(subject.map((item) => [item["section"], item])).values(),
    ];
  }

  const getSelectedSections = () => {
    if (myassessment[1] === "myresources") {
      return gradeSections.map((sec) => {
        const section = `${selectedGrade.name}-${sec.value}`;
        return userDetails.sections[section];
      });
    } else {
      const local_sections = JSON.parse(
        window.localStorage.getItem("sections") || "{sections:[]}"
      );
      return local_sections.sections || [];
    }
  };

  const assignsetsectionId = location.state.sectionId;
  const practiceAssessment = location.state.practiceAssessment;
  const topics = location.state.topics;
  const modules = location.state.modules;
  const [seltopics, setSelTopics] = useState(topics);
  const chapters = location.state.chapters;
  const [selectetdTopicId, setSelectetdTopicId] = useState(topicId);
  const [selectetdChapterId, setSelectetdChapterId] = useState(chapterId);
  const [selectedPracticeAssessment, setSelectedPracticeAssessment] =
    useState(practiceAssessment);
  const [assignSectionId, setAssignSectionId] = useState(assignsetsectionId);

  const tyt = selectedPracticeAssessment?.tyt;
  const labelDropdown = {
    "Chapter Test": "CT",
    "Unit Test": "UT",
    "Half Yearly": "HY",
    "Final Test": "FY",
  };
  const [chaptersState, setChaptersState] = useState([]);
  const [topicsState, setTopicsState] = useState([]);
  const [libselection, setLibSelection] = useState(false);
  // Instance array for the questions of Library Selection
  const [libselectequestion, setLibSelectedQuestion] = useState([]);
  const [libauth, setLibauth] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [randQuesType, setRandQuesType] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState(false);
  const [cong, setCong] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [descTitle, setDescTitle] = useState("");
  const theme = useTheme();
  const [value, setValue] = useState(
    myassessment[1] !== "myassessment" ? 1 : 2
  );
  const [heading, setHeading] = useState("Assessment");
  const [age, setAge] = React.useState("");
  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHeading = (val) => {
    history.goBack();
    setHeading(val);
  };
  const getTopics = async () => {
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${chapterId}`,
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userId}/sections/${sectionId}/subjects/${subjectId}/chapters/${selectetdChapterId}/topics`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        // console.log(response.data, 2333);
        setSelTopics(response.data.data.topics);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Questionquery = (chapterId, topicId, dc = true) => {
    if (topicId != "" && myassessment[1] === "myassessment")
      return `topicId[in]=${topicId.join(",")}&`;
    if (chapterId != "" && myassessment[1] === "myassessment") {
      if (dc) return `chapterId[in]=${chapterId.join(",")}&`;
      else {
        return `chapterId[in]=${chapterId.join(",")}`;
      }
    }
    return "";
  };
  const handleRemoveClickCard = (index) => {
    const list = [...addAuthorQues];
    list.splice(index, 1);
    setAddAuthorQues(list);
  };
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  const classSelected = localStorage.getItem("gradeSection");
  const sectionSelected = classSelected?.split("-")[1];
  const subjectName = location.state.subjectName;
  const chapterName = location.state.chapterName;
  const topicName = location.state.topicName;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    setShow(false);
  };
  const [checkMarksState, setCheckMarksState] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const handleCloseAssignModal = () => setAssignModal(false);
  const handleShowAssignModal = () => setAssignModal(true);
  const [lock, setLock] = useState(false);
  const [checkAssign, setCheckAssign] = useState(true);
  const [moduleStatus, setModuleStatus] = useState(false);
  const [assignSuccessshow, setAssignSuccessshow] = useState(false);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [togglePaper, setTogglePaper] = useState(true);
  const [assignDetails, setAssignDetails] = useState(false);
  const handleCloseAssignSuccessshow = () => setAssignSuccessshow(false);
  const handleShowAssignSuccessshow = () => {
    setAssignSuccessshow(true);
    setAssignModal(false);
  };

  const getQuestions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/staffs/${
          userDetails.userId
        }/sections/${sectionId}/subjects/${subjectId}/questions?${
          myassessment[1] === "myassessment"
            ? Questionquery(selectetdChapterId, selectetdTopicId)
            : `topicId=${topicId}&`
        }page=${page}&limit=5`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setQuestions(response.data.data.questions);
        console.log(questions);
      }
    } catch (error) {
      console.log(error);
    }
    setFetchQuestionLoader(false);
  };

  const countQuestion = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWORIGIN}/api/v1/staffs/${
          userDetails.userId
        }/sections/${sectionId}/subjects/${subjectId}/questions/count?${
          myassessment[1] === "myassessment"
            ? Questionquery(
                selectetdChapterId,
                selectetdTopicId,
                chapterId.length > 1 ? false : true
              )
            : `topicId[in]=${topicId}`
        }`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setQuestionsCount(response.data.data.totalCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopics();
    getQuestions();
    countQuestion();

    if (questId != "" && selQuest != null) {
      upindex = questions.findIndex((question) => question._id === questId);
      questions[upindex] = selQuest;
    }
  }, [
    selQuest,
    arrQues,
    selectetdChapterId,
    selectetdTopicId,
    practiceAssessment,
  ]);
  useEffect(() => {
    if (questions.length > 0) {
      setUserDetails({ ...userDetails, questions: questions });
    }
  }, [questions]);
  useEffect(() => {
    if (myassessment[1] === "myassessment") {
      if (topicId?.length != 0) {
        topicId.forEach((x, i) => {
          setTopicsSel((arr) => [
            ...arr,
            { name: selectedPracticeAssessment?.topic[i], id: x },
          ]);
        });
      }
    }
  }, [topicId]);

  const deleteQuestion = async (id) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ORIGIN}/api/v1/questions/${id}`,
        {
          headers: {
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        alert.success("Question Deleted");
        const newQuestion = questions.filter((ques) => ques._id !== id);
        setQuestions(newQuestion);
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        alert.error("Try Again.");
      } else {
        alert.error("Try Again.");
      }
    }
  };

  const getTotalMarks = (c) => {
    setTotalMarks(totalMarks + c);
  };
  // console.log("chapterId---->310", selectetdChapterId);
  const handleFilterDifficulty = () => {
    questions.forEach((question) => {
      if (question.difficulty === "easy") {
        // console.log("cle");
        filterDifficulty["easy"].push(question);
      }
      if (question.difficulty === "intermediate") {
        filterDifficulty["intermediate"].push(question);
      }
      if (question.difficulty === "hard") {
        filterDifficulty["hard"].push(question);
      }
    });
  };
  const handleFilterCognitive = () => {
    questions.forEach((question) => {
      if (question.cognitive === "knowledge") {
        // console.log("cle");
        filterCognitive["knowledge"].push(question);
      }
      if (question.cognitive === "understanding") {
        filterCognitive["understanding"].push(question);
      }
      if (question.cognitive === "application") {
        filterCognitive["application"].push(question);
      }
      if (question.cognitive === "analysis") {
        filterCognitive["analysis"].push(question);
      }
    });
  };
  handleFilterDifficulty();
  handleFilterCognitive();

  const itemsToShow = questions
    .filter((item) => {
      if (
        difficulty === "easy" ||
        difficulty === "hard" ||
        difficulty === "intermediate"
      ) {
        return item.difficulty === difficulty;
      }
      return true;
    })
    .filter((item) => {
      if (
        cognitive === "knowledge" ||
        cognitive === "understanding" ||
        cognitive === "application" ||
        cognitive === "analysis"
      ) {
        return item.cognitive === cognitive;
      }
      return true;
    });
  const initial = addAuthorQues.length !== 1;

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 320) {
      setVisible(true);
    } else if (scrolled <= 320) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  reviewQuestion?.forEach((x, i) => {});

  const handle_random_save = async () => {
    console.log("Called 550");
    if (
      randomQuestionModal.No_Of_Questions === undefined ||
      randomQuestionModal.Total_Marks === undefined
    ) {
      setRandomQuestionModal({
        ...randomQuestionModal,
        validity: true,
      });
      return;
    }
    setAssisgnTestLoader(true);
    setLock(false);
    setAssignDetails(true);
    setRandomQuestionModal({
      ...randomQuestionModal,
      validity: false,
      btn: true,
    });
    console.log("Called 568", chapterId);
    try {
      let str;
      // if (chapterId.length <= 1) {
      // str = `https://api2xcell-v2.herokuapp.com/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/questions/autocreate?topics=${topicId}&totalquestions=${randomQuestionModal.No_Of_Questions}&totalmarks=${randomQuestionModal.Total_Marks}`;
      // }
      // else {
      str = `${process.env.REACT_APP_FILE_SERVER}/api/v1/staffs/${
        userDetails.userId
      }/sections/${sectionId}/subjects/${subjectId}/questions/autocreate?chapters=${
        chapterId instanceof Array ? chapterId.join() : chapterId
      }&totalquestions=${randomQuestionModal.No_Of_Questions}&totalmarks=${
        randomQuestionModal.Total_Marks
      }`;
      // }

      const response = await axios.get(str, {
        headers: {
          token: authToken,
        },
      });

      if (response.data.status === "success") {
        const que = response.data.data.questions;
        setTotalMarks(response.data.data.totalMarks);
        const mpq =
          response.data.data.totalMarks / response.data.data.fetchedQuestions;
        let new_que = que.map((question) => {
          return { ...question, marks: mpq };
        });
        setReviewQuestion(new_que);
        setReviewed(true);
      }
    } catch (error) {
      console.log(error);
    }
    setRandomQuestionModal({ validity: false, btn: false });
    setShow(false);
    setAssisgnTestLoader(false);
    setStartDate(false);
  };

  const createTest = async () => {
    setCreateTestLoader(true);
    createTestData = {
      name: title,
      description: descTitle,
      testType: labelDropdown[tyt] || "CT",
      questions: reviewQuestion.map((x) => ({
        questionId: x._id,
        marks: x.marks,
      })),
      totalMarks: totalMarks,
      visibiltyStatus: "private",
      chapters: location?.state?.resources ? param.chapterId : [...chapterId],
      topics: location?.state?.resources ? param.topicId : [...topicId],
      sections: getSelectedSections(),
    };
    setcreateData(createTestData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/tests`,
        createTestData,
        {
          headers: {
            // 'content-type': 'multipart/form-data',
            token: authToken,
          },
        }
      );
      if (response.data.status === "success") {
        setTestId(response.data.data.test._id);
        alert.success("Test Created");
        setLock(true);
        setcreateData({});
      }
    } catch (error) {
      if (error.response.data.status === "fail") {
        // setCreateTestMessage(error.response.data.message);
        alert.error(error.response.data.message);
      } else {
        // setCreateTestMessage('Try Again.');
        alert.error("Try Again.");
      }
    }
    setCreateTestLoader(false);
    setcreateData({});
  };
  const [modalState, setModalSate] = useState(false);
  const handleHideModalSate = () => {
    setModalSate(false);
    // console.log("433", selectedPracticeAssessment);
    // window.location.reload();
  };
  const handleOpenModalSate = () => {
    setModalSate(true);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const [dropdown, setdropdown] = useState(isSmallScreen ? false : true);
  const handleShutterOpen = () => {
    setdropdown(!dropdown);
  };
  const evaluateMinEndDate = (date) => {
    const newDateStr = new Date(date);
    const newDate = newDateStr.getDate() + 1;
    newDateStr.setDate(newDate);
    return newDateStr;
  };

  const [selectedGrade, setGrade] = useState({
    name: "",
    sections: [],
  });
  const [gradeSections, setSections] = useState([]);
  useEffect(() => {
    if (!classSelected) return;
    const [selectedGrade, selectedSection] = classSelected.split("-");
    const grades = groupGradeSections(userDetails.grades);
    const secs = (grades[selectedGrade] || []).map((section, index) => ({
      value: section,
      label: section,
      key: index,
    }));
    setGrade({
      name: selectedGrade,
      sections: secs,
    });
    setSections(secs.filter((sec) => sec.value === selectedSection));
  }, [classSelected]);

  useEffect(() => {
    return () => {
      window.localStorage.removeItem("sections");
    };
  }, []);

  return (
    <>
      <Modal
        show={modalState}
        onHide={handleHideModalSate}
        animation={false}
        centered
        className="online_test_modal_head_one"
      >
        <Modal.Body>
          <div className="online_test_modal">
            <div className="online_test_modal_top">
              <div className="online_test_heading">
                <FilterListIcon />

                <span>FILTER</span>
              </div>
            </div>
            <div className="online_test_modal_mid">
              <DropdownComponent
                type="chapter"
                practiceAssessment={selectedPracticeAssessment}
                setPracticeAssessment={setSelectedPracticeAssessment}
                content={chapters}
                setChapterId={setSelectetdChapterId}
                // disable={(practiceAssessment.subject && practiceAssessment.subject != "Subject") ? false : true}
                onlinetest={true}
                setSectionId={setAssignSectionId}
              />
              {/* <Checkbox className="check_drop" /> */}
              <DropdownComponent
                type="topic"
                practiceAssessment={selectedPracticeAssessment}
                setPracticeAssessment={setSelectedPracticeAssessment}
                content={seltopics}
                setTopicId={setSelectetdTopicId}
                setModuleStatus={setModuleStatus}
                checkFilter
                // disable={(practiceAssessment.chapter && practiceAssessment.chapter != "Chapter") ? false : true}
                onlinetest={true}
              />
              {/* <Checkbox className="check_drop" /> */}
              <DropdownComponent
                type="subtopic"
                practiceAssessment={selectedPracticeAssessment}
                setPracticeAssessment={setSelectedPracticeAssessment}
                content={modules}
                // disable={((practiceAssessment.topic && practiceAssessment.topic != "Topic") && !moduleStatus) ? false : true}
                onlinetest={true}
              />
            </div>
            <div className="online_test_modal_bot">
              <button
                className="online_test_modal_cancel"
                onClick={handleHideModalSate}
              >
                Cancel
              </button>
              <NavLink
                to={{
                  redirect: true,
                  pathname: `/myassessment/${selectedPracticeAssessment?.subject}/${subjectId}/onlinetest`,
                  state: {
                    subjectId: subjectId,
                    sectionId: sectionId,
                    topicId: selectetdTopicId,
                    chapterId: selectetdChapterId,
                    practiceAssessment: selectedPracticeAssessment,
                    grade: grade,
                    subject: subject,
                    chapters: chapters,
                    modules: modules,
                  },
                }}
              >
                <button
                  className="online_test_modal_save"
                  onClick={handleHideModalSate}
                >
                  Save
                </button>
              </NavLink>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* randomQues modal */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="online_test_modal_head_one"
      >
        <Modal.Body>
          <div className="randomQues_modal">
            <div className="randomQues_modal_top">
              <svg
                width="19"
                height="22"
                viewBox="0 0 19 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.35714 0C2.10667 0 2.71429 0.615608 2.71429 1.375V4.26423C4.43798 2.48172 6.84071 1.375 9.5 1.375C13.6385 1.375 17.1556 4.05532 18.4591 7.79179C18.7089 8.50777 18.3385 9.29334 17.6318 9.5464C16.9251 9.79947 16.1497 9.4242 15.8999 8.70821C14.9678 6.03613 12.4524 4.125 9.5 4.125C7.28116 4.125 5.30919 5.2044 4.07055 6.875H8.14286C8.89239 6.875 9.5 7.49061 9.5 8.25C9.5 9.00939 8.89239 9.625 8.14286 9.625H1.35714C0.607614 9.625 0 9.00939 0 8.25V1.375C0 0.615608 0.607614 0 1.35714 0ZM1.36822 12.4536C2.07491 12.2005 2.85028 12.5758 3.10006 13.2918C4.03224 15.9639 6.54758 17.875 9.5 17.875C11.7188 17.875 13.6908 16.7956 14.9295 15.125L10.8571 15.125C10.1076 15.125 9.5 14.5094 9.5 13.75C9.5 12.9906 10.1076 12.375 10.8571 12.375H17.6429C18.0028 12.375 18.348 12.5199 18.6025 12.7777C18.857 13.0356 19 13.3853 19 13.75V20.625C19 21.3844 18.3924 22 17.6429 22C16.8933 22 16.2857 21.3844 16.2857 20.625V17.7358C14.562 19.5183 12.1593 20.625 9.5 20.625C5.36152 20.625 1.84442 17.9447 0.540919 14.2082C0.291141 13.4922 0.661539 12.7067 1.36822 12.4536Z"
                  fill="black"
                />
              </svg>
              <span>Random Selection</span>
            </div>
            <div className="randomQues_modal_mid">
              <div className="ques_input">
                <span>No Of Questions</span>
                <input
                  type="number"
                  onInput={(e) => {
                    setRandomQuestionModal({
                      ...randomQuestionModal,
                      No_Of_Questions: parseInt(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="ques_input">
                <span>Total Marks</span>
                <input
                  type="number"
                  onInput={(e) => {
                    setRandomQuestionModal({
                      ...randomQuestionModal,
                      Total_Marks: parseInt(e.target.value),
                    });
                  }}
                />
              </div>
              <span
                className="Validity_Check_Message"
                style={{
                  display: randomQuestionModal.validity
                    ? "inline-flex"
                    : "none",
                }}
              >
                All fields are required
              </span>
            </div>
            <div className="randomQues_modal_bot">
              <button
                className="randomQues_modal_bot_cancel"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="randomQues_modal_bot_save"
                disabled={randomQuestionModal.btn ? true : false}
                onClick={handle_random_save}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* libraryQues modal */}
      <Modal
        show={show1}
        onHide={handleClose1}
        animation={false}
        centered
        className="lib-modal online_test_modal_head_one"
      >
        <Modal.Body>
          <div className="libauthor_modal">
            <div
              className="libauthor_modal_top"
              onClick={() => {
                setCong(false);
                setDifficultyLevel(false);
              }}
            >
              <svg
                width="19"
                height="22"
                viewBox="0 0 19 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.35714 0C2.10667 0 2.71429 0.615608 2.71429 1.375V4.26423C4.43798 2.48172 6.84071 1.375 9.5 1.375C13.6385 1.375 17.1556 4.05532 18.4591 7.79179C18.7089 8.50777 18.3385 9.29334 17.6318 9.5464C16.9251 9.79947 16.1497 9.4242 15.8999 8.70821C14.9678 6.03613 12.4524 4.125 9.5 4.125C7.28116 4.125 5.30919 5.2044 4.07055 6.875H8.14286C8.89239 6.875 9.5 7.49061 9.5 8.25C9.5 9.00939 8.89239 9.625 8.14286 9.625H1.35714C0.607614 9.625 0 9.00939 0 8.25V1.375C0 0.615608 0.607614 0 1.35714 0ZM1.36822 12.4536C2.07491 12.2005 2.85028 12.5758 3.10006 13.2918C4.03224 15.9639 6.54758 17.875 9.5 17.875C11.7188 17.875 13.6908 16.7956 14.9295 15.125L10.8571 15.125C10.1076 15.125 9.5 14.5094 9.5 13.75C9.5 12.9906 10.1076 12.375 10.8571 12.375H17.6429C18.0028 12.375 18.348 12.5199 18.6025 12.7777C18.857 13.0356 19 13.3853 19 13.75V20.625C19 21.3844 18.3924 22 17.6429 22C16.8933 22 16.2857 21.3844 16.2857 20.625V17.7358C14.562 19.5183 12.1593 20.625 9.5 20.625C5.36152 20.625 1.84442 17.9447 0.540919 14.2082C0.291141 13.4922 0.661539 12.7067 1.36822 12.4536Z"
                  fill="black"
                />
              </svg>
              <span>LIBRARY QUESTION</span>
            </div>
            <div className="libauthor_modal_mid">
              <div className="libauthor_modal_mid_up">
                <div className="ques_input">
                  <span
                    onClick={() => {
                      setCong(false);
                      setDifficultyLevel(false);
                    }}
                  >
                    No Of Questions
                  </span>
                  <input
                    type="number"
                    onInput={(e) => {
                      setLibrarySelection({
                        ...librarySelection,
                        No_Of_Questions: parseInt(e.target.value),
                      });
                    }}
                  />
                </div>

                <div className="ques_input">
                  <span
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setCong(false);
                      setDifficultyLevel(false);
                    }}
                  >
                    Difficulty Level
                  </span>
                  <button
                    onClick={() => {
                      setDifficultyLevel(!difficultyLevel);
                      setCong(false);
                    }}
                    className="quesType_button"
                  >
                    <span>Select</span>
                    {/* <img src={downImg}/> */}
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 4.18506C3.87019 4.19255 3.26298 3.95069 2.81079 3.51224L0.114741 0.787561C0.0365664 0.696276 -0.00428284 0.578854 0.00035587 0.45876C0.00499458 0.338666 0.0547796 0.224745 0.139762 0.139763C0.224745 0.0547799 0.338666 0.00499458 0.45876 0.000355868C0.578854 -0.00428284 0.696276 0.0365666 0.787561 0.114741L3.48838 2.81556C3.76146 3.07468 4.12356 3.21912 4.5 3.21912C4.87644 3.21912 5.23854 3.07468 5.51162 2.81556L8.21244 0.114741C8.30373 0.0365666 8.42115 -0.00428284 8.54124 0.000355868C8.66133 0.00499458 8.77526 0.0547799 8.86024 0.139763C8.94522 0.224745 8.99501 0.338666 8.99964 0.45876C9.00428 0.578854 8.96343 0.696276 8.88526 0.787561L6.18921 3.51224C5.73702 3.95069 5.12981 4.19255 4.5 4.18506Z"
                        fill="#CACACA"
                      />
                    </svg>
                  </button>

                  {difficultyLevel && (
                    <span className="test lib-test">
                      <span></span>
                      <div className="test_op">
                        <span>Easy</span>
                        <input
                          type="number"
                          value={librarySelection.Easy}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Easy: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                      <div className="test_op">
                        <span>Medium</span>
                        <input
                          type="number"
                          value={librarySelection.Medium}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Medium: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                      <div className="test_op">
                        <span>Hard</span>
                        <input
                          type="number"
                          value={librarySelection.Hard}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Hard: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </span>
                  )}
                </div>
              </div>
              <div className="libauthor_modal_mid_up">
                {/* <div className="ques_input">
                  <span>Total Marks</span>
                  <input type="number" />
                </div> */}
                <div className="ques_input">
                  <span
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setCong(false);
                      setDifficultyLevel(false);
                    }}
                  >
                    Congnitive Types
                  </span>
                  <button
                    onClick={() => {
                      setCong(!cong);
                      setDifficultyLevel(false);
                    }}
                    className="quesType_button"
                  >
                    <span>Select</span>
                    {/* <img src={downImg}/> */}
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 4.18506C3.87019 4.19255 3.26298 3.95069 2.81079 3.51224L0.114741 0.787561C0.0365664 0.696276 -0.00428284 0.578854 0.00035587 0.45876C0.00499458 0.338666 0.0547796 0.224745 0.139762 0.139763C0.224745 0.0547799 0.338666 0.00499458 0.45876 0.000355868C0.578854 -0.00428284 0.696276 0.0365666 0.787561 0.114741L3.48838 2.81556C3.76146 3.07468 4.12356 3.21912 4.5 3.21912C4.87644 3.21912 5.23854 3.07468 5.51162 2.81556L8.21244 0.114741C8.30373 0.0365666 8.42115 -0.00428284 8.54124 0.000355868C8.66133 0.00499458 8.77526 0.0547799 8.86024 0.139763C8.94522 0.224745 8.99501 0.338666 8.99964 0.45876C9.00428 0.578854 8.96343 0.696276 8.88526 0.787561L6.18921 3.51224C5.73702 3.95069 5.12981 4.19255 4.5 4.18506Z"
                        fill="#CACACA"
                      />
                    </svg>
                  </button>

                  {cong && (
                    <span className="test lib-test-down">
                      <span></span>
                      <div className="test_op">
                        <span>Knowledge</span>
                        <input
                          type="number"
                          value={librarySelection.Knowledge}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Knowledge: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                      <div className="test_op">
                        <span>Understanding</span>
                        <input
                          type="number"
                          value={librarySelection.Understanding}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Understanding: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                      <div className="test_op">
                        <span>Application</span>
                        <input
                          type="number"
                          value={librarySelection.Application}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Application: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                      <div className="test_op">
                        <span>Analysis</span>
                        <input
                          type="number"
                          value={librarySelection.Analysis}
                          onInput={(e) => {
                            setLibrarySelection({
                              ...librarySelection,
                              Analysis: parseInt(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </span>
                  )}
                </div>
              </div>
              <span
                className="Validity_Check_Message"
                style={{
                  display: librarySelection.DLQ_Validity
                    ? "none"
                    : "inline-flex",
                }}
              >
                {/* Requested Difficulty Level Questions are not equal to Total
                Number of Questions */}
                Invalid Number of Difficulty Level Questions
              </span>
              <span
                className="Validity_Check_Message"
                style={{
                  display: librarySelection.Cog_Validity
                    ? "none"
                    : "inline-flex",
                }}
              >
                {/* Requested Cognitive Questions are not equal to Total
                Number of Questions */}
                Invalid Number of Cognitive Type Questions
              </span>
              <span
                className="Validity_Check_Message"
                style={{
                  display: librarySelection.validity ? "inline-flex" : "none",
                }}
              >
                All fields are required
              </span>
              {/* <div className="ques_input" style={{ margin: "auto", width: "48%" }}>
                <span style={{ marginRight: "10px" }}>Question Types</span>
                <button onClick={() => setLibAuthQuest(!libAuthQuest)}
                  className="quesType_button">
                  <span>Select</span>
                  
                  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 4.18506C3.87019 4.19255 3.26298 3.95069 2.81079 3.51224L0.114741 0.787561C0.0365664 0.696276 -0.00428284 0.578854 0.00035587 0.45876C0.00499458 0.338666 0.0547796 0.224745 0.139762 0.139763C0.224745 0.0547799 0.338666 0.00499458 0.45876 0.000355868C0.578854 -0.00428284 0.696276 0.0365666 0.787561 0.114741L3.48838 2.81556C3.76146 3.07468 4.12356 3.21912 4.5 3.21912C4.87644 3.21912 5.23854 3.07468 5.51162 2.81556L8.21244 0.114741C8.30373 0.0365666 8.42115 -0.00428284 8.54124 0.000355868C8.66133 0.00499458 8.77526 0.0547799 8.86024 0.139763C8.94522 0.224745 8.99501 0.338666 8.99964 0.45876C9.00428 0.578854 8.96343 0.696276 8.88526 0.787561L6.18921 3.51224C5.73702 3.95069 5.12981 4.19255 4.5 4.18506Z" fill="#CACACA" />
                  </svg>
                </button>
                {libAuthQuest && (
                  <span className="test" style={{ marginTop: "188px" }}>
                    <span></span>
                    <div className="test_op">
                      <span>Objective questions</span>
                      <input type="number" />
                    </div>
                    <div className="test_op">
                      <span>Descriptive Questions</span>
                      <input type="number" />
                    </div>
                  </span>
                )}
              </div> */}
            </div>
            <div className="libauthor_modal_bot">
              <button
                className="randomQues_modal_bot_cancel"
                onClick={() => {
                  setShow1(false);
                  setCong(false);
                  setDifficultyLevel(false);
                  setLibrarySelection({
                    DLQ_Validity: true,
                    Cog_Validity: true,
                    btn: false,
                  });
                }}
              >
                Cancel
              </button>
              <button
                className="randomQues_modal_bot_save"
                disabled={librarySelection.btn ? true : false}
                onClick={async () => {
                  if (
                    librarySelection.Easy === undefined ||
                    librarySelection.Medium === undefined ||
                    librarySelection.Hard === undefined ||
                    librarySelection.Knowledge === undefined ||
                    librarySelection.Analysis === undefined ||
                    librarySelection.Application === undefined ||
                    librarySelection.Understanding === undefined ||
                    librarySelection.No_Of_Questions === undefined
                  ) {
                    setLibrarySelection({
                      ...librarySelection,
                      validity: true,
                    });
                    return;
                  }
                  if (
                    librarySelection.Easy +
                      librarySelection.Medium +
                      librarySelection.Hard !==
                      librarySelection.No_Of_Questions &&
                    librarySelection.Knowledge +
                      librarySelection.Analysis +
                      librarySelection.Application +
                      librarySelection.Understanding ===
                      librarySelection.No_Of_Questions
                  ) {
                    setLibrarySelection({
                      ...librarySelection,
                      DLQ_Validity: false,
                      Cog_Validity: true,
                      validity: false,
                      btn: false,
                    });
                    return;
                  } else if (
                    librarySelection.Knowledge +
                      librarySelection.Analysis +
                      librarySelection.Application +
                      librarySelection.Understanding !==
                      librarySelection.No_Of_Questions &&
                    librarySelection.Easy +
                      librarySelection.Medium +
                      librarySelection.Hard ===
                      librarySelection.No_Of_Questions
                  ) {
                    setLibrarySelection({
                      ...librarySelection,
                      DLQ_Validity: true,
                      Cog_Validity: false,
                      validity: false,
                      btn: false,
                    });
                    return;
                  } else if (
                    librarySelection.Knowledge +
                      librarySelection.Analysis +
                      librarySelection.Application +
                      librarySelection.Understanding !==
                      librarySelection.No_Of_Questions &&
                    librarySelection.Easy +
                      librarySelection.Medium +
                      librarySelection.Hard !==
                      librarySelection.No_Of_Questions
                  ) {
                    setLibrarySelection({
                      ...librarySelection,
                      DLQ_Validity: false,
                      Cog_Validity: false,
                      validity: false,
                      btn: false,
                    });
                    return;
                  }
                  setLibrarySelection({ ...librarySelection, btn: true });
                  setReviewed(false);
                  setLock(false);
                  setLibSelection(true);
                  setFetchQuestionLoader(true);
                  setLibauth(false);
                  setAssignDetails(false);
                  setAssisgnTestLoader(false);
                  setCreateTestLoader(false);
                  try {
                    let str;
                    // if (chapterId.length === 1) {
                    //   str = `https://api2xcell-v2.herokuapp.com/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/questions/libraryquestions?knowledge=${librarySelection.Knowledge}&understanding=${librarySelection.Understanding}&application=${librarySelection.Application}&analysis=${librarySelection.Analysis}&easy=${librarySelection.Easy}&medium=${librarySelection.Medium}&hard=${librarySelection.Hard}`;
                    // }
                    // else {
                    str = `${process.env.REACT_APP_FILE_SERVER}/api/v1/staffs/${
                      userDetails.userId
                    }/sections/${sectionId}/subjects/${subjectId}/questions/libraryquestions?knowledge=${
                      librarySelection.Knowledge
                    }&understanding=${
                      librarySelection.Understanding
                    }&application=${librarySelection.Application}&analysis=${
                      librarySelection.Analysis
                    }&easy=${librarySelection.Easy}&medium=${
                      librarySelection.Medium
                    }&hard=${librarySelection.Hard}&chapters=${
                      chapterId instanceof Array ? chapterId.join() : chapterId
                    }`;
                    // }
                    const response = await axios.get(str, {
                      headers: {
                        token: authToken,
                      },
                    });
                    console.log("library", response);
                    if (response.data.status === "success") {
                      setQuestionsCount(response.data.result);
                      setLibSelectedQuestion(response.data.data.questions);
                      setQuestions(response.data.data.questions.slice(0, 5));
                    }
                  } catch (error) {
                    if (error.response.data.status === "fail") {
                      console.log(error);
                    }
                  }
                  setFetchQuestionLoader(false);
                  setLibrarySelection({
                    DLQ_Validity: true,
                    Cog_Validity: true,
                    btn: false,
                  });
                  setShow1(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* assign modal */}
      <Modal
        show={assignModal}
        onHide={handleCloseAssignModal}
        animation={false}
        centered
        className="success_assign"
      >
        <Modal.Body>
          <div className="assignModal">
            <div className="assignModal_top">
              <div>
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "10px" }}
                >
                  <path
                    d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                    stroke="#111827"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>ASSIGN</span>
              </div>
              <CloseIcon
                className="closeAssignModal"
                onClick={handleCloseAssignModal}
              />
            </div>

            <div className="assignModal_mid">
              <div
                className="dropDown_assign"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <span>Start Date Time</span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    value={selectedFromDate}
                    className="dateStyle colorSelect"
                    size="small"
                    inputVariant={"filled"}
                    disableToolbar
                    disabled={
                      myassessment[0] === "myassessment"
                        ? assignSection != "Section"
                          ? false
                          : true
                        : false
                    }
                    variant="inline"
                    inputFormat="dd/MM/yyyy hh:mm"
                    margin="normal"
                    minDate={new Date()}
                    onChange={(date) => {
                      setSelectedFromDate(date);
                      setSelectedToDate(evaluateMinEndDate(date));
                      setStartDate(true);
                      setEndDate(true);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "From Date",
                      id: "urlFromDate",
                    }}
                    autoOk={true}
                  />
                </LocalizationProvider>
              </div>
              <div
                className="dropDown_assign"
                style={{ width: "100%", marginBottom: "10px" }}
              >
                <span>End Date Time</span>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    className="dateStyle colorSelect"
                    size="small"
                    inputVariant={"filled"}
                    disableToolbar
                    disabled={startDate ? false : true}
                    variant="inline"
                    inputFormat="dd/MM/yyyy hh:mm"
                    margin="normal"
                    minDate={evaluateMinEndDate(
                      selectedFromDate ? true : false
                    )}
                    value={selectedToDate}
                    onChange={(date) => {
                      setSelectedToDate(date);
                      // const time = new Date(date).getTime() - new Date(selectedFromDate).getTime()
                      // setAssignTime(getDateDifference(time))
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "From Date",
                      id: "urlToDate",
                    }}
                    autoOk={true}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <br></br>

            <div className="assignModal_mid-row">
              <div className="dropDown_assign" style={{ width: "50%" }}>
                <span style={{ marginBottom: "9px" }}>Duration</span>
                <div className="assignTimeDate">
                  <TextField
                    value={assignTime.days}
                    disabled={endDate ? false : true}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 10 },
                      placeholder: "DD",
                    }}
                    className="text-field-input margin-15 text-field color"
                    id="outlined-basic"
                    label="DD"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        display: "none",
                      },
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setAssignTime(({ day, hours, minutes }) => ({
                        day: e.target.value,
                        hours,
                        minutes,
                      }));
                    }}
                  />
                  <TextField
                    value={assignTime.hours}
                    disabled={endDate ? false : true}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 24 },
                      placeholder: "HH",
                    }}
                    className="text-field-input margin-15 text-field color"
                    id="outlined-basic"
                    label="HH"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        display: "none",
                      },
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setAssignTime(({ day, hours, minutes }) => ({
                        day,
                        hours: e.target.value,
                        minutes,
                      }));
                    }}
                  />
                  <TextField
                    value={assignTime.minutes}
                    disabled={endDate ? false : true}
                    style={{
                      border: "transparent",
                    }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 59 },
                      placeholder: "MM",
                      style: {
                        border: "transparent !important",
                      },
                    }}
                    className="text-field-input margin-10 text-field color"
                    id="outlined-basic"
                    label="MM"
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        display: "none",
                      },
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setAssignTime(({ day, hours, minutes }) => ({
                        day,
                        hours,
                        minutes: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="dropDown_assign" style={{ width: "50%" }}>
                <span>Set Visibility</span>
                <Dropdown className="assignModal_dropdown">
                  {/* option-dropdown */}
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="btn-question-type"
                    disabled={assignTime != "00:00" ? false : true}
                  >
                    {assignVisible}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="onl-dropdown menu_option_extend scrollmenu"
                    style={{ height: "170px" }}
                  >
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="private"
                      className="menu_option1 onl-drop-list"
                    >
                      private
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="publicForSchool"
                      className="menu_option1 onl-drop-list"
                    >
                      public for school
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onSelect={handleVisible}
                      eventKey="publicForAll"
                      className="menu_option1 onl-drop-list"
                    >
                      public for all
                      <div>
                        <input type="radio" name="radio" />
                        <span className="colorRed"></span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {myassessment[1] === "myresources" ? (
              <div className="dropDown_assign" style={{ width: "100%" }}>
                <span>Sections</span>
                <Select
                  styles={customStyles}
                  options={selectedGrade.sections}
                  closeMenuOnSelect={false}
                  placeholder={"Select"}
                  className={`${`dropdown mb-3 w-100`}`}
                  value={gradeSections}
                  onChange={(selectedOption) => {
                    setSections(selectedOption);
                  }}
                  isMulti
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#CDFFE1",
                    },
                  })}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="assignModal_bot">
              {assisgnTestLoader ? (
                <>
                  <div className="text-center">
                    <CircularProgress
                      style={{ color: "#FF075B", fontWeight: "600" }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="review-onlineTest"
                    onClick={async () => {
                      const sDate = new Date(selectedFromDate).getTime();
                      const eDate = new Date(selectedToDate).getTime();
                      const time =
                        (assignTime.day || 0) * 24 * 60 * 60 * 1000 +
                        (assignTime.hours || 0) * 60 * 60 * 1000 +
                        (assignTime.minutes || 0) * 60 * 1000;
                      // {day, hours, minutes}
                      setAssisgnTestLoader(true);
                      setLock(false);
                      setCheckAssign(true);
                      setAssignDetails(true);
                      try {
                        const topicQuery =
                          myassessment[1] != "myresources"
                            ? {}
                            : { topicId: topicId };

                        const data = {
                          startDate: sDate,
                          endDate: eDate,
                          duration: time,
                          visibilityStatus: assignVisible,
                          ...topicQuery,
                        };

                        data.sections = getSelectedSections();

                        const response = await axios.post(
                          `${process.env.REACT_APP_ORIGIN}/api/v1/staffs/${userDetails.userId}/sections/${sectionId}/subjects/${subjectId}/tests/${testId}/assign`,
                          data,
                          {
                            headers: {
                              token: authToken,
                            },
                          }
                        );
                        if (response.data.status === "success") {
                          setStartDate(false);
                          setAssisgnTestLoader(false);
                          handleShowAssignSuccessshow();
                        }
                      } catch (error) {
                        console.log(error);
                        if (error.response?.data?.status === "fail") {
                          setAssisgnTestLoader(false);
                          console.log(error);
                        }
                      }
                    }}
                  >
                    Submit
                  </button>
                  <br />
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal */}
      <Modal
        show={assignSuccessshow}
        onHide={handleCloseAssignSuccessshow}
        animation={false}
        centered
        className="online_test_modal_head_one"
      >
        <Modal.Body>
          <div className="upload_done_modal">
            <div
              className="upload_done_modal__top"
              style={{ justifyContent: "space-between" }}
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 15C2.79086 15 1 13.2091 1 11C1 9.09295 2.33457 7.4976 4.12071 7.09695C4.04169 6.74395 4 6.37684 4 6C4 3.23858 6.23858 1 9 1C11.4193 1 13.4373 2.71825 13.9002 5.00098C13.9334 5.00033 13.9666 5 14 5C16.7614 5 19 7.23858 19 10C19 12.419 17.2822 14.4367 15 14.9M13 12L10 9M10 9L7 12M10 9L10 21"
                  stroke="#111827"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ left: "15%", position: "absolute" }}>Assign</span>
              <CloseIcon onClick={handleCloseAssignSuccessshow} />
            </div>
            <div className="upload_done_modal__mid">
              <span
                style={{ fontSize: "16px" }}
              >{`Test Assigned To ${assignClass} Successfully`}</span>
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70ZM51.2186 29.3436C52.9271 27.635 52.9271 24.865 51.2186 23.1564C49.51 21.4479 46.74 21.4479 45.0314 23.1564L30.625 37.5628L24.9686 31.9064C23.26 30.1979 20.49 30.1979 18.7814 31.9064C17.0729 33.615 17.0729 36.385 18.7814 38.0936L27.5314 46.8436C29.24 48.5521 32.01 48.5521 33.7186 46.8436L51.2186 29.3436Z"
                  fill="#5CDA8E"
                />
              </svg>
            </div>
            <div className="upload_done_modal__bottom">
              <button
                className="btn modal_done colorModal"
                onClick={handleCloseAssignSuccessshow}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className={isSmallScreen ? "modules my-4" : "modules my-5"}>
        <div
          className={
            isSmallScreen ? "container-fluid dashboard_container" : "container"
          }
        >
          <div className="box">
            <div
              className="upperBox"
              style={{ display: isSmallScreen ? "none" : "" }}
            >
              <div className="alignItem">
                <div className="boxRow">
                  <div className="pathDetail" style={{ color: "white" }}>
                    <h1
                      style={{ fontWeight: "600", color: "#ffffff !important" }}
                    >
                      {myassessment[1] === "myassessment"
                        ? selectedPracticeAssessment?.class
                        : classSelected}
                    </h1>
                    <h2 style={{ fontWeight: "700", color: "black" }}>
                      Online Test
                    </h2>
                    {myassessment[1] === "myassessment" ? (
                      <p style={{ fontWeight: "600", color: "#ffffff" }}>
                        {console.log(selectedPracticeAssessment?.topic)}
                        {selectedPracticeAssessment?.subject} &gt;{" "}
                        {selectedPracticeAssessment?.chapter} &gt;{" "}
                        {selectedPracticeAssessment?.topic.join(", ")} &gt;
                        {heading}
                      </p>
                    ) : (
                      <p style={{ fontWeight: "600", color: "#ffffff" }}>
                        {subjectName} &gt; {chapterName} &gt; {topicName} &gt;
                        {heading}
                      </p>
                    )}
                  </div>
                  <div className="DateBox">
                    <h5 className="ms-auto" style={{ fontWeight: "600" }}>
                      <CalendarTodayIcon />{" "}
                      {<Moment format="DD MMMM, YYYY">{new Date()}</Moment>}
                    </h5>
                  </div>
                </div>
                {/* <div className='col-sm-2 mt-4'>
                    <Link
                      to='/createtest'
                      className=' fw-bolder createTest'
                      style={{ textDecoration: 'none' }}
                    >
                      <AddBoxOutlinedIcon /> Create Assessment
                    </Link>

                    <p
                      className='fw-bolder my-3'
                      data-bs-toggle='modal'
                      data-bs-target='#assesmentContentUploadModal'
                      style={{ cursor: 'pointer' }}
                    >
                      <CloudUploadOutlinedIcon /> Upload Assessment
                    </p>
                  </div> */}
              </div>
            </div>
            {myassessment[1] !== "myassessment" ? (
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  onChange={handleChange}
                  className="ps"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={moduleImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Modules"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Modules")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            ) : (
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{
                    background: "#DCFFFB",
                    marginTop: "-12px",
                    display: dropdown ? "" : "none",
                  }}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons={"on"}
                >
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={HomeIcon}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Home"
                    {...a11yProps(0)}
                    onClick={() => changeHeading("Dashboard")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={analyticGraphImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Analytics"
                    {...a11yProps(1)}
                    onClick={() => changeHeading("Analytics")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assignImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assign Task"
                    {...a11yProps(2)}
                    onClick={() => changeHeading("Assign Task")}
                  />
                  <Tab
                    style={{ color: "#10A0B6" }}
                    icon={
                      <img
                        src={assessmentImg}
                        style={{ height: "23px", width: "23px" }}
                      />
                    }
                    label="Assessment"
                    {...a11yProps(3)}
                    onClick={() => changeHeading("Assessment")}
                  />
                </Tabs>
              </Paper>
            )}
            {
              <Paper
                className={classes.root}
                style={{
                  background: "#DCFFFB",
                  display: dropdown ? "" : "none",
                }}
              >
                <div className="assesment_header">
                  <div
                    className="assesment_header__left"
                    style={{ flexDirection: isSmallScreen ? "column" : "row" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "row" : "row",
                        margin: isSmallScreen ? "0px" : "0px",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="authorQuesButton"
                        onClick={() => {
                          setLibauth(false);
                          setShow1(false);
                          setShow(true);
                        }}
                      >
                        <img src={randomQuesImg} />
                        <span className="fontSize_12">Randomise Selection</span>
                      </button>
                      {/* <button
                        className="uploadQues"
                        onClick={() => {
                          setLibauth(false);
                          setShow(false);
                          setShow1(true);
                        }}
                      >
                        <img src={librarySelImg} />
                        <span className="fontSize_12">Library Selection</span>
                      </button> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "row" : "row",
                        margin: isSmallScreen ? "0px" : "0px",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="libauth"
                        onClick={() => setLibauth(!libauth)}
                      >
                        <img src={libautImg} />
                        <span className="fontSize_12">Library+Author</span>
                      </button>
                      {myassessment[1] === "myassessment" ? (
                        <button
                          className="uploadQues"
                          onClick={handleOpenModalSate}
                        >
                          <FilterListIcon />
                          <span className="fontSize_12">Filter</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="assesment_header__right">
                    <div className="marks">
                      Q <br />
                      {reviewed ? reviewQuestion.length : questionsCount}
                    </div>
                    <div
                      className="selector"
                      style={reviewed ? { display: "none" } : {}}
                    >
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {difficulty}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="Difficulty"
                          >
                            All<span>{questions.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="easy"
                          >
                            easy<span>{filterDifficulty.easy.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="intermediate"
                          >
                            intermediate{" "}
                            <span>{filterDifficulty.intermediate.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onSelect={handleSelect1}
                            eventKey="hard"
                          >
                            Hard <span>{filterDifficulty.hard.length}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {cognitive}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="drop-menu">
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="Cognitive"
                          >
                            All<span>{questions.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="knowledge"
                          >
                            knowledge{" "}
                            <span>{filterCognitive.knowledge.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="understanding"
                          >
                            understanding{" "}
                            <span>{filterCognitive.understanding.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="application"
                          >
                            application{" "}
                            <span>{filterCognitive.application.length}</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="drop-menu-list"
                            onSelect={handleSelect2}
                            eventKey="analysis"
                          >
                            analysis{" "}
                            <span>{filterCognitive.analysis.length}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </Paper>
            }
            <div
              className="shutter_PS"
              style={{ marginTop: dropdown ? "0px" : "-15px" }}
            >
              <DropDownShutter
                onClick={handleShutterOpen}
                clicked={dropdown}
                dropdown={dropdown}
                setDropDown={setdropdown}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container my-4"> */}
      <div className="onlineTest_up">
        <div className="onlineTest">
          <div className="mobilepart">
            <span>
              {labelDropdown[selectedPracticeAssessment?.tyt] || "CT"}
            </span>
            <span style={{ float: "right", borderRadius: "10px 0px 0px 10px" }}>
              {totalMarks}
            </span>
          </div>
          <div className="onlineTest_head">
            <div className="input-group mb-3 input-attach-group2">
              <input
                type="text"
                className="form-control"
                placeholder="Test Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div className="onlinetest_marks">
                <span>Total Marks</span>
                <span
                  style={{
                    backgroundColor: "#FFD8E4",
                    color: "#FF5087",
                    fontSize: "18px",
                  }}
                >
                  {totalMarks}
                </span>
              </div>
            </div>
          </div>
          <div className="onlineTest_botm">
            <div className="input-group mb-3 input-attach-group2">
              <input
                type="text"
                className="form-control"
                placeholder="Test Description"
                value={descTitle}
                onChange={(e) => setDescTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
        {assignDetails && (
          <AssignedTestDetails
            Testclass={selectedPracticeAssessment?.class || selectedGrade.name}
            section={
              myassessment[1] === "myassessment"
                ? selectedPracticeAssessment?.section || ""
                : sectionSelected
            }
            date={selectedFromDate}
            time={assignTime}
            testType={labelDropdown[selectedPracticeAssessment?.tyt] || "CT"}
            totalMarks={totalMarks}
            chapters={selectedPracticeAssessment?.chapter || chapterName}
            topics={selectedPracticeAssessment?.topic || topicName}
          />
        )}
        {libauth && (
          <>
            {addAuthorQues.map((x, index) => (
              <>
                <AuthorQues
                  selSubjectId={subjectId}
                  selChapterId={selectetdChapterId}
                  selTopicId={selectetdTopicId}
                  selSectionId={sectionId}
                  setAuthorQues={setLibauth}
                  handleRemoveClickCard={handleRemoveClickCard}
                  index={index}
                  initial={initial}
                  setArrQues={setArrQues}
                  isMarks
                  checkreview
                  topicsSel={topicsSel}
                  libauth={libauth}
                  isTopic={chapterId.length < 2 ? true : false}
                  setLibauth={setLibauth}
                  setReviewQuestion={() => {}}
                  resources={location?.state?.resources}
                />
              </>
            ))}
            <div className="submit_author_question">
              <button
                type="button"
                className="btn btn-add-question"
                onClick={() =>
                  setAddAuthorQues((arr) => [...arr, addAuthorQues.length])
                }
              >
                <svg
                  className="MuiSvgIcon-root ico-block"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                </svg>{" "}
                Add Question
              </button>
              {/* {console.log("setAddAuthorQues", addAuthorQues)} */}
            </div>
          </>
        )}
        <br />

        {fetchQuestionLoader ? (
          <div className="text-center">
            <CircularProgress style={{ color: "#FF075B", fontWeight: "600" }} />
          </div>
        ) : (
          <div
            className="onlineTest_Card_main_body"
            style={{ display: libauth ? "none" : "block" }}
          >
            {fetchQuestionLoader ? (
              <div className="text-center">
                <CircularProgress
                  style={{ color: "#FF075B", fontWeight: "600" }}
                />
              </div>
            ) : (
              <div
                className="container slider"
                style={reviewed ? { display: "none" } : { display: "" }}
              >
                <LeftArrow
                  className="slider-img"
                  // onClick={(e) => handleSlide(e, true)}
                  // style={checkPage?{display:"none"}:{display:"block"}}
                  style={
                    page === 1 || itemsToShow.length === 0
                      ? { visibility: "hidden" }
                      : { display: "" }
                  }
                  onClick={async () => {
                    setFetchQuestionLoader(true);
                    if (page <= 1) {
                      setFetchQuestionLoader(false);
                      return;
                    }
                    page = page - 1;
                    setPage(page);
                    libselection
                      ? setQuestions(
                          [...libselectequestion].splice(page * 5 - 5, 5)
                        )
                      : await getQuestions();
                    setFetchQuestionLoader(false);
                  }}
                  color="#FF8CB0"
                />
                <div
                  className="pageNumber"
                  style={
                    reviewed || libauth
                      ? { display: "none" }
                      : { background: "#FF8CB0" }
                  }
                >
                  {page}/
                  {Math.ceil(questionsCount / 5) === 0
                    ? 1
                    : Math.ceil(questionsCount / 5)}
                </div>
                <RightArrow
                  className="slider-img floatright"
                  // onClick={(e) => handleSlide(e)}
                  style={
                    itemsToShow.length === 0 ||
                    page >= Math.ceil(questionsCount / 5)
                      ? { visibility: "hidden" }
                      : { display: "block" }
                  }
                  onClick={async () => {
                    setFetchQuestionLoader(true);
                    page = page + 1;
                    setPage(page);
                    console.log("Pages", page * 5 + 1, page * 5 + 6);
                    libselection
                      ? setQuestions(
                          [...libselectequestion].splice((page - 1) * 5, 5)
                        )
                      : await getQuestions();
                    setFetchQuestionLoader(false);
                  }}
                  color="#FF8CB0"
                />
              </div>
            )}
            {(userDetails?.filterQuestions
              ? userDetails?.filterQuestions
              : reviewed
              ? reviewQuestion
              : itemsToShow
            ).map((question, i) => (
              <>
                {question.questionType === "mcq" ? (
                  <div className="onlineTest_Card" key={i}>
                    <McqCard
                      isChecked
                      question={question}
                      index={i + 1}
                      deleteQuestion={deleteQuestion}
                      setQuestId={setQuestId}
                      setSelQuest={setSelQuest}
                      isMarks={true}
                      page={page}
                      getTotalMarks={getTotalMarks}
                      reviewQuestion={reviewQuestion}
                      setReviewQuestion={setReviewQuestion}
                      setChecked={setChecked}
                      setChaptersState={setChaptersState}
                      chaptersState={chaptersState}
                      setTopicsState={setTopicsState}
                      topicsState={topicsState}
                      setCheckMarksState={setCheckMarksState}
                      isPraticeSession={false}
                    />
                    <div className="ques_marks">
                      <span>Marks</span>
                      <span
                        style={{
                          backgroundColor: "#FF7FA7",
                          color: "#FF5087",
                          fontSize: "18px",
                        }}
                      >
                        <QuestionMarks
                          id={question._id}
                          reviewQuestion={reviewQuestion}
                          setReviewQuestion={setReviewQuestion}
                          getTotalMarks={getTotalMarks}
                          checked={checked}
                          mark={show ? question.marks || 0 : question.marks}
                          setTotalMarks={setTotalMarks}
                          totalMarks={totalMarks}
                          checkMarksState={checkMarksState}
                          setQuestions={setQuestions}
                          questions={questions}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {question.questionType === "mmcq" ? (
                  <div className="onlineTest_Card" key={i}>
                    <MmcqCard
                      question={question}
                      index={i + 1}
                      deleteQuestion={deleteQuestion}
                      setQuestId={setQuestId}
                      setSelQuest={setSelQuest}
                      isMarks={true}
                      page={page}
                      isChecked
                      getTotalMarks={getTotalMarks}
                      reviewQuestion={reviewQuestion}
                      setReviewQuestion={setReviewQuestion}
                      setChecked={setChecked}
                      setChaptersState={setChaptersState}
                      chaptersState={chaptersState}
                      setTopicsState={setTopicsState}
                      topicsState={topicsState}
                      setCheckMarksState={setCheckMarksState}
                      isPraticeSession={false}
                    />
                    <div className="ques_marks">
                      <span>Marks</span>
                      <span
                        style={{
                          backgroundColor: "#FF7FA7",
                          color: "#FF5087",
                          fontSize: "18px",
                        }}
                      >
                        <QuestionMarks
                          id={question._id}
                          reviewQuestion={reviewQuestion}
                          setReviewQuestion={setReviewQuestion}
                          getTotalMarks={getTotalMarks}
                          checked={checked}
                          mark={question.marks || 0}
                          setTotalMarks={setTotalMarks}
                          totalMarks={totalMarks}
                          checkMarksState={checkMarksState}
                          setQuestions={setQuestions}
                          questions={questions}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {question.questionType === "correctstatement" ? (
                  <div className="onlineTest_Card" key={i}>
                    <TFCard
                      isChecked
                      question={question}
                      index={i + 1}
                      deleteQuestion={deleteQuestion}
                      setQuestId={setQuestId}
                      setSelQuest={setSelQuest}
                      isMarks={true}
                      page={page}
                      getTotalMarks={getTotalMarks}
                      reviewQuestion={reviewQuestion}
                      setReviewQuestion={setReviewQuestion}
                      setChecked={setChecked}
                      setChaptersState={setChaptersState}
                      chaptersState={chaptersState}
                      setTopicsState={setTopicsState}
                      topicsState={topicsState}
                      setCheckMarksState={setCheckMarksState}
                    />
                    <div className="ques_marks">
                      <span>Marks</span>
                      <span
                        style={{
                          backgroundColor: "#FF7FA7",
                          color: "#FF5087",
                          fontSize: "18px",
                        }}
                      >
                        <QuestionMarks
                          id={question._id}
                          reviewQuestion={reviewQuestion}
                          setReviewQuestion={setReviewQuestion}
                          getTotalMarks={getTotalMarks}
                          checked={checked}
                          mark={question.marks || 0}
                          setTotalMarks={setTotalMarks}
                          totalMarks={totalMarks}
                          checkMarksState={checkMarksState}
                          setQuestions={setQuestions}
                          questions={questions}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {question.questionType === "oneword" ? (
                  <div className="onlineTest_Card" key={i}>
                    <OneWord
                      isChecked
                      question={question}
                      index={i + 1}
                      deleteQuestion={deleteQuestion}
                      setQuestId={setQuestId}
                      setSelQuest={setSelQuest}
                      isMarks={true}
                      page={page}
                      getTotalMarks={getTotalMarks}
                      reviewQuestion={reviewQuestion}
                      setReviewQuestion={setReviewQuestion}
                      setChecked={setChecked}
                      setChaptersState={setChaptersState}
                      chaptersState={chaptersState}
                      setTopicsState={setTopicsState}
                      topicsState={topicsState}
                      setCheckMarksState={setCheckMarksState}
                    />
                    <div className="ques_marks">
                      <span>Marks</span>
                      <span
                        style={{
                          backgroundColor: "#FF7FA7",
                          color: "#FF5087",
                          fontSize: "18px",
                        }}
                      >
                        <QuestionMarks
                          id={question._id}
                          reviewQuestion={reviewQuestion}
                          setReviewQuestion={setReviewQuestion}
                          getTotalMarks={getTotalMarks}
                          checked={checked}
                          mark={question.marks || 0}
                          setTotalMarks={setTotalMarks}
                          totalMarks={totalMarks}
                          checkMarksState={checkMarksState}
                          setQuestions={setQuestions}
                          questions={questions}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {question.questionType === "fillup" ? (
                  <div className="onlineTest_Card" key={i}>
                    <FillBlankCard
                      isChecked
                      question={question}
                      index={i + 1}
                      deleteQuestion={deleteQuestion}
                      setQuestId={setQuestId}
                      setSelQuest={setSelQuest}
                      isMarks={true}
                      page={page}
                      getTotalMarks={getTotalMarks}
                      reviewQuestion={reviewQuestion}
                      setReviewQuestion={setReviewQuestion}
                      setChecked={setChecked}
                      setChaptersState={setChaptersState}
                      chaptersState={chaptersState}
                      setTopicsState={setTopicsState}
                      topicsState={topicsState}
                      setCheckMarksState={setCheckMarksState}
                    />
                    <div className="ques_marks">
                      <span>Marks</span>
                      <span
                        style={{
                          backgroundColor: "#FF7FA7",
                          color: "#FF5087",
                          fontSize: "18px",
                        }}
                      >
                        <QuestionMarks
                          id={question._id}
                          reviewQuestion={reviewQuestion}
                          setReviewQuestion={setReviewQuestion}
                          getTotalMarks={getTotalMarks}
                          checked={checked}
                          setTotalMarks={setTotalMarks}
                          totalMarks={totalMarks}
                          mark={question.marks || 0}
                          checkMarksState={checkMarksState}
                          setQuestions={setQuestions}
                          questions={questions}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <DescCard/> */}
              </>
            ))}
            {fetchQuestionLoader ? (
              <div className="text-center">
                <CircularProgress
                  style={{ color: "#FF075B", fontWeight: "600" }}
                />
              </div>
            ) : (
              <>
                {createTestLoader ? (
                  <>
                    <div className="text-center">
                      <CircularProgress
                        style={{ color: "#FF075B", fontWeight: "600" }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {reviewed ? (
                      <div
                        className="assignReviewSection"
                        style={
                          reviewQuestion.length === 0
                            ? { display: "none" }
                            : { display: "flex", marginTop: "10px" }
                        }
                      >
                        {checkAssign ? (
                          <button
                            className="submit-onlineTest"
                            style={{ margin: "0px" }}
                            onClick={() => {
                              setTestId("");
                              setCreateTestMessage("");
                              setLock(false);
                              setcreateData({});
                              setCheckAssign(false);
                              setTitle("");
                              setDescTitle("");
                              setReviewed(false);
                            }}
                          >
                            Re-Create
                          </button>
                        ) : (
                          <button
                            className="submit-onlineTest"
                            style={{ margin: "0px" }}
                            onClick={() => setReviewed(false)}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          className="submit-onlineTest"
                          style={lock ? null : { background: "#FF8CB0" }}
                          onClick={() => {
                            createTest();
                          }}
                          disabled={lock}
                        >
                          Save
                        </button>
                        <button
                          className="review-onlineTest"
                          disabled={lock ? false : true}
                          style={
                            lock
                              ? {
                                  background: "#FF8CB0",
                                  border: "1px solid #FF8CB0",
                                }
                              : {
                                  background: "#E8E8E8",
                                  border: "1px solid #E8E8E8",
                                }
                          }
                          onClick={() => {
                            handleShowAssignModal();
                          }}
                        >
                          Assign
                        </button>
                      </div>
                    ) : (
                      <div
                        className="reviewSection"
                        style={
                          reviewQuestion.length === 0
                            ? { display: "none" }
                            : { display: "flex" }
                        }
                      >
                        {/* <button className="submit-onlineTest">Submit</button> */}
                        <button
                          className="review-onlineTest"
                          onClick={() => setReviewed(true)}
                        >
                          Review
                        </button>
                      </div>
                    )}
                    <br />
                    {createTestMessage && (
                      <div className="text-center">
                        <p style={{ color: "#FF075B", fontWeight: "600" }}>
                          {createTestMessage}
                        </p>
                      </div>
                    )}
                  </>
                )}

                <div
                  className="container slider"
                  style={
                    itemsToShow.length === 0 || reviewed
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  <LeftArrow
                    className="slider-img"
                    // onClick={(e) => handleSlide(e, true)}
                    // style={checkPage?{display:"none"}:{display:"block"}}
                    style={
                      page === 1 || itemsToShow.length === 0
                        ? { visibility: "hidden" }
                        : { display: "" }
                    }
                    onClick={async () => {
                      setFetchQuestionLoader(true);
                      if (page <= 1) {
                        setFetchQuestionLoader(false);
                        return;
                      }
                      page = page - 1;
                      setPage(page);
                      libselection
                        ? setQuestions(
                            [...libselectequestion].splice(page * 5 - 5, 5)
                          )
                        : await getQuestions();
                      setFetchQuestionLoader(false);
                    }}
                    color="#FF8CB0"
                  />
                  <RightArrow
                    className="slider-img floatright"
                    // onClick={(e) => handleSlide(e)}
                    style={
                      itemsToShow.length === 0 ||
                      page >= Math.ceil(questionsCount / 5)
                        ? { display: "none" }
                        : { display: "block" }
                    }
                    onClick={async () => {
                      setFetchQuestionLoader(true);
                      page = page + 1;
                      setPage(page);
                      console.log("Pages", page * 5 + 1, page * 5 + 6);
                      libselection
                        ? setQuestions(
                            [...libselectequestion].splice((page - 1) * 5, 5)
                          )
                        : await getQuestions();
                      setFetchQuestionLoader(false);
                    }}
                    color="#FF8CB0"
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div className="scroll_button_head">
          {visible && (
            <div className="scroll_button" onClick={scrollToTop}>
              <KeyboardArrowUpIcon className="scroll" />
              {/* <span>scroll up</span> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
